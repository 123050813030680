import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductosfiltrosService {
  token: string | null;
  constructor(private http: HttpClient) { 
    const userLocal = localStorage.getItem('userKaizen');
    if (userLocal) {
      this.token = JSON.parse(userLocal).token;
    } else {
      this.token = "No exite token";
    }
  }

  // Servicio que obtiene las marcas de los repuestos
  getMarcas() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/repuesto/marca`;
    return this.http.get<any>(url, httpOptions);
  }
// Obtener filtros de una marca
getecommerceRepuestos(per_page: any, page: any) {
  const httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    })
  };
  const url = `${environment.base_url}/repuesto/marca/todas?page=${page}&per_page=${per_page}`;
  return this.http.get<any>(url, httpOptions);
}
  // Obtener filtros de una marca
  getFiltrosMarca(id: any, per_page: any, page: any, tipo: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/repuesto/marca/${id}?per_page=${per_page}&page=${page}&tipo_repuesto=${tipo}`;
    return this.http.get<any>(url, httpOptions);
  }

  // Obtener filtros de una marca
  getFiltrosMarcaSinPaginacion(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/repuesto/marca/${id}`;
    return this.http.get<any>(url, httpOptions);
  }

  // Obtener filtros de una marca
  /*getRepuestosHome() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/repuesto/marca/todas`;
    return this.http.get<any>(url, httpOptions);
  }*/

  // Obtener filtros de una marca
  getRepuestosHomePaginacion( per_page: any, page: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/repuesto/marca/todas?per_page=${per_page}&page=${page}`;
    return this.http.get<any>(url, httpOptions);
  }

  // Obtener informacion de los filtros
  getFiltrosInformacion(marca: any, id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/repuesto/${marca}/${id}`;
    return this.http.get<any>(url, httpOptions);
  }

    // Servicio que obtiene las marcas de los repuestos
    buscarFiltro(termino: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'application/json'
        })
      };
      const url = `${environment.base_url}/repuesto/q?search=${termino}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene las marcas de los vehiculos
    getMarcasRepuestos() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'application/json'
        })
      };
      const url = `${environment.base_url}/vehiculo/marca`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene los modelos por marca de vehiculos
    getModelosMarcas(marca: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'application/json'
        })
      };
      const url = `${environment.base_url}/vehiculo/marca/${marca}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene los modelos por marca de vehiculos
    getAniosModelo(modelo: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'application/json'
        })
      };
      const url = `${environment.base_url}/vehiculo/marca/modelo/${modelo}/anio`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene los motores por año para el modelo seleccionado
    getMotoresAnio(modelo: any, anio: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'application/json'
        })
      };
      const url = `${environment.base_url}/vehiculo/marca/modelo/${modelo}/anio/${anio}/motor`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que filtra repuestos por modelo
    filtroRepuestosPorMarca(marca: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'application/json'
        })
      };
      const url = `${environment.base_url}/repuesto/filtrado?marca=${marca}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que filtra repuestos por modelo
    filtroRepuestosPorModelo(modelo: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'application/json'
        })
      };
      const url = `${environment.base_url}/repuesto/filtrado?modelo=${modelo}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que filtra repuestos por modelo
    filtroRepuestosPorAnio(modelo: any, anio: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'application/json'
        })
      };
      const url = `${environment.base_url}/repuesto/filtrado?modelo=${modelo}&anio=${anio}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que filtra repuestos por modelo
    filtroRepuestosPorMotor(modelo: any, anio: any, motor: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'application/json'
        })
      };
      const url = `${environment.base_url}/repuesto/filtrado?modelo=${modelo}&anio=${anio}&motor=${motor}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Servicio que obtiene las marcas de los repuestos
  getCategorias() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/repuesto/categoria`;
    return this.http.get<any>(url, httpOptions);
  }

  // Obtener filtros de una marca
  getRepuestosPromociones( per_page: number, page: number, idprom:number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    //const url = `${environment.base_url}/repuesto/marca/todas?per_page=${per_page}&page=${page}`;
    const url = `${environment.base_url}/repuesto/marca/todas?per_page=${per_page}&page=${page}&oferta=${idprom}`;
    return this.http.get<any>(url, httpOptions);
  }

  // Obtener filtros de una marca
  getRepuestosPromocionID(idprom:number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/repuesto/marca/todas?oferta=${idprom}`;
    return this.http.get<any>(url, httpOptions);
  }
  // Obtener filtros de una marca
  getRepuestosPromocionesTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/repuesto/marca/todas?oferta=3`;
    return this.http.get<any>(url, httpOptions);
  }
  // Obtener filtros de una marca
  getRepuestosPromocionesNoviembre() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/repuesto/marca/todas?oferta=4`;
    return this.http.get<any>(url, httpOptions);
  }
}

<!--<div class="top-header">
    Somos un taller autorizado por <span>Toyosa</span>
</div>-->
<div class="back-kaizen" *ngIf="!noMostrar">
    <div nz-row>
        <div nz-col [nzXs]="{span: 0 }" [nzMd]="{ span: 0 }" [nzLg]="{ span: 22, offset: 1 }" class="kaizen-header" (mouseenter)="submenu = false">
            <div nz-row>
                <div nz-col nzSpan="4" class="logo">
                    <a routerLink="/">
                        <img src="{{logo}}" alt="Logo Kaizen Motors" width="260" height="77"/>
                    </a>
                </div>
                <div nz-col nzSpan="15">
                    <div nz-row class="top-menu">
                        <div nz-col nzSpan="23" class="search-input">
                            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                                <input type="text" nz-input placeholder="Qué estas buscando?" [(ngModel)]="searchDesktop" (keyup)="onKey($event)"/>
                            </nz-input-group>
                            <ng-template #suffixIconButton>
                                <button nz-button nzSearch (click)="linkSearch()" class="btn-search back-kaizen" style="background: red;color: #fff;"><i nz-icon nzType="search" nzTheme="outline"></i> Buscar</button>
                            </ng-template>
                        </div>
                    </div>
                    <div nz-row class="menu">
                            <ul nz-menu nzMode="horizontal" nzSpan="24" class="nav-menu nav-menu_header">
                                <li nz-menu-item class="nav-menu_item"><a href="/tienda-online">Tienda Online</a></li>
                                <!--<nz-divider nzType="vertical"></nz-divider>
                                <li nz-menu-item class="nav-menu_item camion-item"><a class="menu" (click)= "submenuFiltrosVisible()" >Marcas</a>
                                    <div nz-row class="filtros" *ngIf="submenuFiltros" #target>
                                        <ul nz-col nzSpan="24" nz-menu [nzMode]="'vertical'" class="nav-menu nav-menu_header">
                                            <li nz-menu-item class="nav-menu_item" *ngFor="let marcasFiltros of marcas; let i = index">
                                                <a href="{{getUrl(marcas[i]['nombre_marca_repuesto'])}}">{{marcas[i]['nombre_marca_repuesto']}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>-->
                                <nz-divider nzType="vertical"></nz-divider>
                                <li nz-menu-item class="nav-menu_item"><a href="/servicio-tecnico">Servicio Técnico</a></li>
                                <nz-divider nzType="vertical"></nz-divider>
                                <!--<li nz-menu-item class="nav-menu_item"><a href="/servicio-tecnico/agendar-cita">Agendar cita</a></li>-->
                                <li nz-menu-item class="nav-menu_item"><a href="/chaperio">Chaperio</a></li>
                                <nz-divider nzType="vertical"></nz-divider>
                                <!--<li nz-menu-item class="nav-menu_item"><a routerLink="/ofertas/pintado-express">Pintado Express</a></li>
                                <nz-divider nzType="vertical"></nz-divider>
                                <li nz-menu-item class="nav-menu_item"><a routerLink="/ofertas/pack-de-mantenimiento">Pack de Mantenimiento</a></li>-->
                                <li nz-menu-item class="nav-menu_item"><a href="/liquidacion/repuestos"><img src="../../assets/images/iconos/price-tag2.svg" width="20" height="20" alt="icono cyberweek" class="mini-icon"/> Liquidación de repuestos</a></li>
                                <nz-divider nzType="vertical"></nz-divider>
                                <li nz-menu-item class="nav-menu_item"><a routerLink="/sucursales" routerLinkActive="active">Sucursales</a></li>
                                <nz-divider nzType="vertical"></nz-divider>
                                <li nz-menu-item class="nav-menu_item"><a routerLink="/contacto" routerLinkActive="active">Contacto</a></li>
                                
                            </ul>
                    </div>
                </div>                    
                <div nz-col nzSpan="5" class="content-header-right">
                    <div>
                        <ul nz-menu nzMode="horizontal" class="menu-bottom-right">
                            <li nz-menu-item class="submenu_itemCart btn-menu_login" *ngIf="!logueado">
                                <!--<div>
                                    <a routerLink="/login" routerLinkActive="active" class="lnk-lgn new-is"> Iniciar Sesión</a>
                                </div>-->
                                <div>
                                    <a routerLink="usuario/registro" routerLinkActive="active" class="lnk-lgn"> Registrarme</a>
                                </div>
                            </li>
                            <li nz-menu-item class="submenu_itemCart btn-menu_login count-cart" *ngIf="logueado">
                                <nz-badge [nzCount]="cantidadCart">
                                    <a routerLink="/carrito" class="kaizen-cart">
                                        <span _ngcontent-c1="" class="icon-cart"></span>
                                    </a>
                                </nz-badge>
                            </li>
                            <li nz-menu-item class="submenu_itemCart">
                                <a (click)="submenuvisible()" class="menu-header_sesion"><i nz-icon nzType="user" nzTheme="outline"></i> <span *ngIf="!logueado">Iniciar sesion</span> <span class="nm-user" *ngIf="logueado">{{nombreUsuario}}</span></a>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div nz-row class="submenu" *ngIf="submenu" > 
            <div nz-col nzSpan="24" style="background: #ececec; padding: 30px; border-radius: 2px;" class="container-submenu"> 
                <nz-row [nzGutter]="20">
                    <div nz-col nzSpan="24" class="menu-user" *ngIf="!logueado">
                        <div class="menu-description">
                            Inicie sesión para comprar repuestos online y agendar citas en nuestro taller.
                            <a routerLink="login" class="link-login">Iniciar Sesión</a>
                        </div>
                    </div>
                    <div nz-col nzSpan="24" class="menu-user" *ngIf="logueado">
                        <nz-avatar nzIcon="user" class="submenu-user"></nz-avatar>
                        <button nz-button [nzType]="'dashed'" (click)="optionUser()" style="margin-left: 16px; vertical-align: middle;">
                            <span class="nm-user">{{nombreUsuario}}</span>
                        </button>
                        <a (click)="logout()" class="link-out" *ngIf="logueado"><i nz-icon nzType="logout" nzTheme="outline"></i> Salir</a>
                    </div>
                    <div nz-col nzSpan="24">
                        <div nz-row>
                            <nz-col [nzSpan]="24">
                                
                                <!--<nz-card class="card-menu" *ngIf="logueado">
                                    <a routerLink="/repuesto/asakashi" routerLinkActive="active" class="icon_menu">
                                        <span class="icon-offers-kaizen"><span class="path1"></span><span class="path2"></span></span> 
                                        <span class="text_menu font-regular">Ofertas</span>
                                    </a>
                                </nz-card>  -->
                            </nz-col>
                            <nz-col [nzSpan]="12">
                                <nz-card class="card-menu" *ngIf="logueado">
                                    <a routerLink="/usuario/perfil" class="icon_menu">
                                        <span class="icon-kaizen-micuenta"></span>
                                        <span class="text_menu font-regular">Tu Perfil</span>
                                    </a> 
                                </nz-card>
                                <!--<nz-card class="card-menu" *ngIf="!logueado">
                                    <a routerLink="/repuesto/asakashi" routerLinkActive="active" class="icon_menu">
                                        <span class="icon-offers-kaizen"><span class="path1"></span><span class="path2"></span></span> 
                                        <span class="text_menu font-regular">Ofertas</span>
                                    </a>
                                </nz-card>-->
                            </nz-col>
                            <nz-col [nzSpan]="12">
                                
                                <nz-card class="card-menu" *ngIf="logueado">
                                    <a routerLink="/carrito" routerLinkActive="active" class="icon_menu">
                                        <span class="icon-kaizen-carrito"></span>
                                        <span class="text_menu font-regular">Tu Carrito</span>
                                    </a> 
                                </nz-card>
                                <!--<nz-card class="card-menu" *ngIf="!logueado">
                                    <a routerLink="/repuesto/asakashi" routerLinkActive="active" class="icon_menu">
                                        <span class="icon-offers-kaizen"><span class="path1"></span><span class="path2"></span></span> 
                                        <span class="text_menu font-regular">Ofertas</span>
                                    </a>
                                </nz-card>-->
                            </nz-col>
                        </div>
                        <!--<div nz-row class="row-menu">
                            <nz-col [nzSpan]="12">
                                <nz-card class="card-menu">
                                    <a routerLink="/repuesto/asakashi" routerLinkActive="active" class="icon_menu">
                                        <span class="icon-kaizen-catalogo"></span>
                                        <span class="text_menu font-regular">Catálogo</span>
                                    </a> 
                                </nz-card>  
                            </nz-col>
                            <nz-col [nzSpan]="12">
                                <nz-card class="card-menu">
                                    <a routerLink="/usuario/historial" routerLinkActive="active" class="icon_menu">
                                        <span class="icon-kaizen-pedido"></span>
                                        <span class="text_menu font-regular">Tu historial</span>
                                    </a> 
                                </nz-card>
                            </nz-col>
                        </div>
                        <div nz-row class="row-menu">
                            <nz-col [nzSpan]="12">
                                <nz-card class="card-menu">
                                    <a routerLink="/contacto" routerLinkActive="active" class="icon_menu">
                                        <span class="icon-kaizen-sucursales"></span>
                                        <span class="text_menu font-regular">Sucursales</span>
                                    </a> 
                                </nz-card>  
                            </nz-col>
                            <nz-col [nzSpan]="12">
                                <nz-card class="card-menu">
                                    <a routerLink="/carrito" routerLinkActive="active" class="icon_menu">
                                        <span class="icon-kaizen-carrito"></span>
                                        <span class="text_menu font-regular">Tu Carrito</span>
                                    </a> 
                                </nz-card>
                            </nz-col>
                        </div>-->
                        <div nz-row class="row-menu">
                            <nz-col [nzSpan]="24">
                                <nz-card style="background: #ffffff; text-align: center;">
                                    <a routerLink="usuario/registro" routerLinkActive="active" class="icon_menu-line"><span class="icon-kaizen-help"></span></a> 
                                    <a routerLink="usuario/registro" routerLinkActive="active" class="text_menu-line font-regular"> Ayuda</a>
                                </nz-card>  
                            </nz-col>
                        </div>
                    </div>
                </nz-row>
            </div>
        </div>
    </div>
</div>

<div nz-col [nzXs]="{span: 24, offset: 0 }" [nzMd]="{ span: 24, offset: 0 }" [nzLg]="{ span: 0 }" class="back-kaizen menu-kaizen_mobile">
    <nz-col [nzXs]="{span: 22, offset: 1 }">
        <app-mobile></app-mobile>
    </nz-col>
</div>